
import {
    defineComponent, reactive, ref,
    PropType
} from 'vue';
import { device } from '@/methods/rule';
import HttpRequest from '@/util/axios.config';
import { getApiForIdentity } from './util';

type Identity = 'super' | 'dis' | 'community' | 'office' | 'single';
export default defineComponent({
    props: {
        id: {
            type: String,
            required: true
        },
        identity: {
            type: String as PropType<Identity>,
            required: true
        }
    },
    setup(props, { emit }) {
        const formEle = ref<any>();
        const formData = reactive({
            MAC: ''
        });

        const rules = {
            MAC: [
                { required: true, message: WordList.RuleMacEmpty, trigger: 'blur' },
                { validator: device.checkMAC, trigger: 'blur' }
            ]
        };

        const submit = () => {
            // 获取所有角色的操作接口
            const apiForIdentity = getApiForIdentity(props.identity);
            formEle.value.validate((valid: boolean) => {
                if (valid) {
                    HttpRequest.post(apiForIdentity.addMac, { ...formData, ID: props.id }, () => {
                        emit('close');
                        emit('success');
                    });
                }
            });
        };

        return {
            formData,
            rules,
            formEle,
            submit
        };
    }
});
