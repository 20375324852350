
import {
    defineComponent, PropType,
    reactive, ref
} from 'vue';
import {
    listPagination, ListHeaderItem, RequestData
} from '@/components/common/list/index';
import addButton from '@/components/common/add-button/index.vue';
import HttpRequest from '@/util/axios.config';
import addMac from './add.vue';
import { getApiForIdentity } from './util';

const headersNormal: ListHeaderItem[] = [{
    name: 'MAC',
    label: WordList.TabelPersonDeviceInHtmlMAC
}, {
    name: 'Installer',
    label: WordList.ProperAllTextInstaller
}, {
    name: 'Owner',
    label: WordList.TabelPersonDeviceInHtmlOwner
}, {
    name: 'CreateTime',
    label: WordList.TabelMessageBoxCreateTime
}];

const headersSubdis: ListHeaderItem[] = [{
    name: 'MAC',
    label: WordList.TabelPersonDeviceInHtmlMAC
}, {
    name: 'Installer',
    label: WordList.ProperAllTextInstaller
}, {
    name: 'SubDis',
    label: WordList.SubDistributor
}, {
    name: 'Owner',
    label: WordList.TabelPersonDeviceInHtmlOwner
}, {
    name: 'CreateTime',
    label: WordList.TabelMessageBoxCreateTime
}];

const headersSuper: ListHeaderItem[] = [{
    name: 'MAC',
    label: WordList.TabelPersonDeviceInHtmlMAC
}, {
    name: 'Distributor',
    label: WordList.ProperAllTextRoleDistributor
}, {
    name: 'Installer',
    label: WordList.ProperAllTextInstaller
}, {
    name: 'Owner',
    label: WordList.TabelPersonDeviceInHtmlOwner
}, {
    name: 'CreateTime',
    label: WordList.TabelMessageBoxCreateTime
}];

const searchKeyList = [{
    name: 'MAC',
    label: WordList.TabelPersonDeviceInHtmlMAC
}];

type Identity = 'super' | 'dis' | 'community' | 'office' | 'single'|'subdis';
export default defineComponent({
    components: {
        listPagination,
        addButton,
        addMac
    },
    props: {
        id: {
            type: String
        },
        identity: {
            type: String as PropType<Identity>,
            required: true
        }
    },
    setup(props) {
        // 获取所有角色的操作接口
        const apiForIdentity = getApiForIdentity(props.identity);
        const requestData: RequestData = reactive(apiForIdentity.getMacList);
        let headers = props.identity === 'super' ? headersSuper : headersNormal;
        if (props.identity === 'subdis') {
            headers = headersSubdis;
        }
        if (props.id && (props.identity === 'super' || props.identity === 'dis')) requestData.param.ID = props.id;

        const updateToList = ref(0);

        const isShowAddDialog = ref(false);
        const add = () => {
            isShowAddDialog.value = true;
        };

        const fileEle = ref<any>();
        const choose = () => {
            console.log(fileEle.value);
            fileEle.value.click();
        };
        const importMac = () => {
            const param = new FormData();
            param.append('Devices', fileEle.value.files[0]);
            param.append('ID', props.id!);
            HttpRequest.post(apiForIdentity.uploadFile, param, () => {
                updateToList.value += 1;
                fileEle.value.value = '';
            }, () => {
                updateToList.value += 1;
                fileEle.value.value = '';
            });
        };

        // dis身份存在下载模板按钮
        const downloadTemplate = () => {
            window.open('/download/template/upload_mac.csv');
        };

        return {
            headers,
            requestData,
            searchKeyList,
            updateToList,
            add,
            isShowAddDialog,
            fileEle,
            choose,
            importMac,
            downloadTemplate,
            apiForIdentity
        };
    }
});
