import {
    RequestData
} from '@/components/common/list/index';

interface ApiReturnType {
    getMacList: RequestData;
    addMac: string;
    deleteMac: string;
    uploadFile: string;
}

export default null;

/**
 * 根据角色获取具体的接口
 * @date 2022-08-04
 * @param {any} identity:'super'|'dis'|'community'|'office'|'single' 角色
 * @returns {ApiReturnType} 返回相关操作接口
 */
export function getApiForIdentity(identity: 'super' | 'dis' | 'community' | 'office' | 'single'|'subdis'): ApiReturnType {
    if (identity === 'community') {
        return {
            getMacList: {
                url: 'v3/web/community/macLibrary/getList',
                param: {
                    searchKey: 'MAC',
                    searchValue: ''
                }
            },
            addMac: 'v3/web/community/macLibrary/add',
            deleteMac: 'v3/web/community/macLibrary/delete',
            uploadFile: 'v3/web/community/macLibrary/uploadFile'
        };
    }
    if (identity === 'office') {
        return {
            getMacList: {
                url: 'v3/web/office/macLibrary/getList',
                param: {
                    searchKey: 'MAC',
                    searchValue: ''
                }
            },
            addMac: 'v3/web/office/macLibrary/add',
            deleteMac: 'v3/web/office/macLibrary/delete',
            uploadFile: 'v3/web/office/macLibrary/uploadFile'
        };
    }
    if (identity === 'single') {
        return {
            getMacList: {
                url: 'v3/web/single/macLibrary/getList',
                param: {
                    searchKey: 'MAC',
                    searchValue: ''
                }
            },
            addMac: 'v3/web/single/macLibrary/add',
            deleteMac: 'v3/web/single/macLibrary/delete',
            uploadFile: 'v3/web/single/macLibrary/uploadFile'
        };
    }
    if (identity === 'super') {
        return {
            getMacList: {
                url: 'v3/web/common/macLibrary/getList',
                param: {
                    searchKey: 'MAC',
                    searchValue: '',
                    ID: ''
                }
            },
            addMac: 'v3/web/common/macLibrary/addForSuper',
            deleteMac: 'v3/web/common/macLibrary/deleteForSuper',
            uploadFile: 'v3/web/common/macLibrary/uploadFileForSuper'
        };
    }
    return {
        getMacList: {
            url: 'v3/web/common/macLibrary/getList',
            param: {
                searchKey: 'MAC',
                searchValue: '',
                ID: ''
            }
        },
        addMac: 'v3/web/common/macLibrary/add',
        deleteMac: 'v3/web/common/macLibrary/delete',
        uploadFile: 'v3/web/common/macLibrary/uploadFile'
    };
}
