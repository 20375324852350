
import { defineComponent } from 'vue';
import macLibrary from '@/components/view/common/mac-library';

export default defineComponent({
    components: {
        macLibrary
    },
    props: {
        id: {
            type: String
        }
    }
});
